exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pago-completado-js": () => import("./../../../src/pages/pago-completado.js" /* webpackChunkName: "component---src-pages-pago-completado-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-devolucion-js": () => import("./../../../src/pages/politica-de-devolucion.js" /* webpackChunkName: "component---src-pages-politica-de-devolucion-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-solicitud-js": () => import("./../../../src/pages/solicitud.js" /* webpackChunkName: "component---src-pages-solicitud-js" */),
  "component---src-pages-terminos-y-condiciones-de-uso-js": () => import("./../../../src/pages/terminos-y-condiciones-de-uso.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-de-uso-js" */)
}

